import './pricing.scss';
import React from 'react';
import { HLocation } from '@reach/router';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import PageHeader from '@page-components/pricing/PageHeader';
import Thumbnail from '@page-components/pricing/assets/thumbnail.jpg';
import PricingConsumptionTabs from '@page-components/pricing/PricingPlans/PricingConsumptionTabs';
import { PRICING_TABS } from '@constants/PricingTabsTypes';

const PricingPage = ({ location }: { location: HLocation }) => {
  const params = new URLSearchParams(location.search);
  const tab = params.get('tab');

  return (
    <IndexLayout
      pageName="pricing"
      helmet={{
        title: 'TileDB Pricing - For individuals, universities, and organizations',
        description:
          'Empowering individuals, universities, and organizations to manage complex data and run scalable compute operations with ease. Seamlessly organize users into teams and optimize workflows for any scale.',
        shareImage: {
          url: Thumbnail,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent-white" />
      <main className="main">
        <PageHeader />
        <PricingConsumptionTabs initialActiveTab={tab === PRICING_TABS.CONSUMPTIONS ? PRICING_TABS.CONSUMPTIONS : PRICING_TABS.PLANS} />
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default PricingPage;
